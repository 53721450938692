<template>
  <div class="main">
    <div class="top center">公司做人做事十二大准则</div>
    <div class="top2 center">生活的美丽来自选择的真谛。三杉的美丽源于独创的科技</div>
    <div class="con center">
      <div class="box" v-for="(item, index) in conList" :key="index">
        <div class="num center">{{item.num}}</div>
        <div class="text center">{{item.text}}</div>
      </div>
    </div>
    <div class="con center">
      <div class="box" v-for="(item, index) in boxList" :key="index">
        <div class="num center">{{item.num}}</div>
        <div class="text center">{{item.text}}</div>
      </div>
    </div>
  </div>
   
</template>

<script>
  export default {
    data () {
      return {
        conList:[
          {
            num:"01",
            text:"遵章守纪，服从管理"
          },
          {
            num:"02",
            text:"团结拼搏，友爱互助"
          },  {
            num:"03",
            text:"包容谦让，海阔天空"
          },
          {
            num:"04",
            text:"诚信做人，踏实做事"
          },
          {
            num:"05",
            text:"常怀感恩，礼尚往来"
          },
          {
            num:"06",
            text:"有令必行，行出必果"
          },  
        ],
        boxList:[
          {
            num:"07",
            text:"执行到底，不找借口"
          },
          {
            num:"08",
            text:"说到做到，有错必改"
          },  {
            num:"09",
            text:" 客户至上，服务第一"
          },
          {
            num:"10",
            text:"快速回复，及时解决"
          },
          {
            num:"11",
            text:"积极工作，敢于担责"
          },
          {
            num:"12",
            text:"爱厂爱家，尊老爱幼"
          },  
        ]
      };
    }
  }
</script>


<style scoped>
.top{

font-size: 36px;
font-weight: 500;
letter-spacing: 0px;
line-height: 52.13px;
color: rgba(51, 51, 51, 1);



}
.top2{

font-size: 14px;
font-weight: 500;
letter-spacing: 0px;
line-height: 20.27px;
color: rgba(154, 154, 154, 1);
margin-top: 5px;
padding-bottom: 43px;

}

.main{
 width: 1920px;
 height: 549px;
}
.con{
  display: flex;
}
.box{
width: 201px;
height: 122px;
border: 1px solid black;
}
.num{
font-size: 30px;
font-weight: 500;
letter-spacing: 0px;
line-height: 43.44px;
color: rgba(193, 193, 193, 1);
padding-top: 18px;

}
.text{
font-size: 16px;
font-weight: 500;
letter-spacing: 0px;
line-height: 23.17px;
color: rgba(94, 94, 94, 1);
padding-top: 15px;
}
.center{
  display: flex;
text-align: center;
justify-content: center;
margin: auto;  
}


</style>
