<template>
  <div id="app" class="container">


   

    <img alt="Vue logo" src="./assets/toppic.png" class="pic">
    <HelloWorld/>
    <HelloVue  />
    <HelloLive  />
   <div class="low">
      <div class="eng">ABOUT</div>
      <div class="eng2">US</div>
      <img src="/src/assets/line3.png" alt="" class="line3">
      <div class="about">关于我们</div>
      <div class="jieshao center">
        <div class="tx1">杭州逸川科技有限公司，成立于2021年8月20日，正式挂牌开业。</div>
        <div class="tx1">位于浙江省杭州市，是一家以从事软件和信息技术服务业为主的企业。</div>
      </div>
   </div>
    <div class="last">
        <div class="lianxi center" >联系我们</div>
        <div class="welcome center">您可以通过以下方式与我们取得联系，欢迎您的</div>
        <div class="icon">
          <div class="iconbox"  v-for="(item, index) in iconList" :key="index">
            <img alt="Vue logo" :src="item.pic" class="pic2 center" >
            <span class="q center">{{item.name}}</span>
            <span class="w q center">{{item.text}}</span>
          </div>
        </div>
        
        <div  class="kll center">
          <img src="../src/assets/sign.png" alt="" class="pw">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33019202002462" rel="noreferrer" target="_blank" class="e">浙公网安备33019202002462</a>&nbsp;&nbsp;&nbsp;<a href="" @click="open"  class="e">浙ICP备2022023693号</a>
        </div>

    </div>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import HelloVue from './components/HelloVue.vue'
import HelloLive from './components/HelloLive.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    HelloVue,
    HelloLive,
  },
  data() {
      return {
  
      
      iconList:[
        {
          pic:require('@/assets/tel.png'),
          name:"电话",
          text:''

        },
        {
          pic:require('@/assets/msg.png'),
          name:"邮箱",
          text:''

        },
        {
          pic:require('@/assets/local.png'),
          name:"地址",
          text:''

        }
      ]
      };
    },


    methods: {
      open(){
        window.open('https://beian.miit.gov.cn')
      }
    }
}
</script>

<style>
html {
  font-size: calc(100vw / 1920 * 16); 
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0px;
}
.center{
  display: flex;
  text-align: center;
  justify-content: center;
  margin: auto;  
}
.top{
  display: flex;
  /* justify-content: space-between; */
  margin-top: 19px;
}



.pic{
  width: 1920px;
  height: 1080px;
  background: rgba(8, 39, 84, 1);

  margin-bottom: 99px;
}


.pic2{
  width: 100px;
  height: 100px;
}
.telpic{
  width: 20px;
  height: 20px;
  color: rgba(22, 77, 160, 1);
  margin-top: 4px;
  padding-left: 20px;
}
.telnum{
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 40px;
  color: rgba(22, 77, 160, 1);
  margin-left: 10px;

}

.lianxi{
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 57.92px;
  color: rgba(255, 255, 255, 1);
  padding-top: 50px;

}
.welcome{
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(255, 255, 255, 1);
}
.icon{
  width: 772px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding-top: 90px;
}
.iconbox{
  display: flex;
  flex-direction: column;
 
}
.last{
  background-image: url('/src/assets/night.png');
  width: 1920px;
  height: 569px;
  background-size:cover;
  background-repeat:no-repeat;
 
}
.q{
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  padding-top: 26px;
}
.kll{
  padding-top: 80px;
}
.e{
  /* padding-top: 70px; */
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);



}
.w{
  width: 205px;
  height: 70px;
}
.pw{
  width:16px ;
  height: 17px;
  margin-right: 5px;
}
.low{
  width: 1920px;
  height: 1080px;
  background-image: url('/src/assets/gs.png');
  background-size:cover;
  background-repeat:no-repeat;
}
.eng{
font-size: 60px;
font-weight: 400;
color: rgba(255, 255, 255, 1);
padding-top: 129px;
margin-left: 194px;
}
.eng2{
font-size: 60px;
font-weight: 400;
color: rgba(255, 255, 255, 1);
margin-left: 194px;
}
.line3{

width: 60px;
height: 4px;
opacity: 0.5;
background: rgba(255, 255, 255, 1);
margin-left: 194px;
}
.about{

font-size: 30px;
font-weight: 400;
letter-spacing: 1.5px;
line-height: 43.44px;
color: rgba(255, 255, 255, 1);
margin-left: 194px;
margin-top: 23px;

}
.jieshao{

width: 1520px;
height: 220px;
opacity: 0.88;
border-radius: 60px 0 0 0; 
background: rgba(23, 63, 143, 1);
margin-top: 364px;
display: flex;
flex-direction: column;
}
.tx1{

font-size: 18px;
font-weight: 500;
letter-spacing: 0px;
line-height: 26.06px;
color: rgba(255, 255, 255, 1);
margin-bottom: 5px;


}

</style>
