<template>
  <div class="main">
    <div class="t1">
      <div class="title">Industrial</div>
      <div class="title1">layout</div>
      <img src="../assets/line2.png" alt="" class="line2">
      <div class="serve">平台服务</div>
    </div>
    <div  class="t2">
      <div class="pics">
        <img src="../assets/store.png" alt=""  class="pics">
      </div>
      
      <div class="main1" v-for="(item, index) in ttList" :key="index">
        <div class="left2">
          <div class="e1">{{ item.a }}</div>
          <div class="e2">{{ item.b }}</div>
        </div>
        <div class="left1">
          <div class="con1">{{ item.c }}</div>
          <div class="con">{{ item.d }}</div>
        </div>
        <div>
        </div>
      </div>
      
    </div>


    <div  class="t3">
  
      <div class="main1" v-for="(item, index) in nnList" :key="index">
        <div class="left2">
          <div class="e1">{{ item.a }}</div>
          <div class="e2">{{ item.b }}</div>
        </div>
        <div class="left1">
          <div class="con1">{{ item.c }}</div>
          <div class="con">{{ item.d }}</div>
        </div>
        <div>
        </div>
      </div>
      
    </div>

  </div>
  
     
  </template>
  
  <script>
    export default {

        components: {
       
   
    },
      data () {
        return {
          ttList:[
            {
              a:"网络安全",
              b:"保护服务",
              c:"定级备案",
              d:"依据《网络安全等级保护定级指南》辅助用户进行定级，组织专家评审，协助向公安机关备案"
            },
            {
              a:"",
              b:"",
              c:"整改加固",
              d:"依据差距分析结果，对信息系统进行安全建设和整改"
            },
            {
              a:"",
              b:"",
              c:"差距分析",
              d:"分析已定级的信息系统所采取的安全保护措施与等保2.0标准要求之间的差距，提出整改建议"
            },
            {
              a:"",
              b:"",
              c:"辅助测评",
              d:"辅助测评机构，现场协助用户对定级系统进行等级测评"
            }
          ],
          nnList:[
          {
              a:"全方位",
              b:"等保服务",
              c:"等保合规咨询服务",
              d:"通过远程或者现场访谈的方式为客户进行等保合规差距分析，量身定制等保解决方案。"
            },
            {
              a:"",
              b:"",
              c:"等保整改服务",
              d:"对系统、拓扑、软件环境、安全策略、网络架构、安全现状进行收集出具《整改建议》，并帮助用户进行系统整改，以确保达到等保测评要求。"
            },
            {
              a:"",
              b:"",
              c:"系统漏洞自查服务",
              d:"在正式测评之前对系统进行一次整体的自查，对提前发现已经存在的漏洞，并进行解决修复。"
            },
            {
              a:"",
              b:"",
              c:"等保定级备案服务",
              d:"协助客户确认定级对象，并组织专家评审准备定级报告同时为客户准备备案材料和进行等保备案"
            },
            {
              a:"",
              b:"",
              c:"等保测评服务",
              d:"协助客户进行等保测评工作并保障顺利通过等保测评获得测评报告"
            },
            {
              a:"",
              b:"",
              c:"系统安全加固服务",
              d:"根据系统自查报告和差距测评报告，对存在的风险进行修复和加固达到测评合规要求。"
            }
          ]
        };
      },

      methods:{

      },
   
    }
  </script>
  
  
  <style scoped>
.main{
  display: flex;
  padding-bottom: 100px;
}
.main1{
  display: flex;
  height: 125px;
  width: 555px;
  border-bottom: 2px solid  rgba(233, 233, 233, 1); /* 只设置下边框 */
}
.t1 {
  display: flex;
  flex-direction: column;
}
.t2{
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.t3{
  margin-left: 130px;
  padding-top: 70px;
}
.title{
font-size: 60px;
font-weight: 500;
letter-spacing: 0px;
line-height: 86.88px;
color: rgba(230, 230, 230, 1);
padding-top: 97px;
padding-left: 194px;
}
.title1{
font-size: 60px;
font-weight: 500;
letter-spacing: 0px;
line-height: 86.88px;
color: rgba(230, 230, 230, 1);
padding-left: 194px;
}
.line2{
width: 60px;
height: 4px;
opacity: 1;

margin-left: 194px;
}
.serve{

font-size: 30px;
font-weight: 500;
letter-spacing: 1.5px;
line-height: 43.44px;
color: rgba(57, 57, 57, 1);
padding-left: 194px;
margin-top: 23px;

}
.con{

width: 421px;
height: 54px;

font-size: 16px;
font-weight: 500;
letter-spacing: 0.08px;
line-height: 23.17px;
color: rgba(67, 67, 67, 1);
margin-top: 13px;
}
.con1{

font-size: 16px;
font-weight: 500;
letter-spacing: 0.08px;
line-height: 23.17px;
color: rgba(57, 57, 57, 1);
padding-top: 25px;


}
.e1{
font-size: 20px;
font-weight: 500;
color: rgba(184, 184, 184, 1);
padding-left: 29px;
padding-top: 25px;

}
.e2{
font-size: 20px;
font-weight: 500;
color: rgba(184, 184, 184, 1);
padding-left: 29px;

}
.left1{
  padding-left: 25px;
}
.left2{
  height: 125px;
  width: 109px;
}
.pics{
  width: 555px;
  height: 350px;
}
  </style>
  