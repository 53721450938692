<template>
    <div>
     <div class="bigbox">
        <div class="w">
          <div class="left">
            <div class="title">Industrial</div>
            <div class="title">layout</div>
            <img src="../../src/assets/line3.png" alt="" class="line2">
            <div class="name">产业布局</div>
            
            <div class="list">
                <div class="name1"  v-for="(item, index) in boxList" :key="index" >{{ item.excel }}</div>
            </div>

          </div>
          <div class="right">
            <div class="blue">
              <div class="bluetext">
                <div class="a">提供智慧解决方案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;规划\设计\研发&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;工程\维护\服务智慧城市、智慧园区、智慧公用、智慧环境、智慧安全、智慧制造、智慧供应链、智慧能源、智慧水务、智慧社区智慧招商、智慧农业</div>
                <div class="a b">提供大数据服务&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数据采集\数据处理&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数据应用\数据展现数据运营\VR统一数据集成、知识库搭建、企业数字化转型</div>
              </div>
            </div>
          </div>


        </div>


     </div>
   
    </div>
   
     
  </template>
  
  <script>
  
    export default {

        components: {
  
   
     },
      data () {
        return {
            boxList:[
              {
                excel:"智慧园区"
              },
              {
                excel:"智慧社区"
              },
              {
                excel:"智慧公用"
              },
              {
                excel:"VR/MR/AR"
              },
              {
                excel:"智慧农业"
              },
              {
                excel:"智慧党建"
              }
            ]
        };
      },

     computed: {
        
     },
      methods:{
    
      }
    }
  </script>
  
  
  <style scoped>

.bigbox{
  background-image: url('/src/assets/build.png');
  width: 1920px;
  height: 1080px;
  background-size:cover;
  background-repeat:no-repeat;
 
}
.w{
  display: flex;
  justify-content: space-between;
}
.left{
  padding: 94px 0 0 196px;
  flex-direction: column;
  
}
.title{

font-size: 60px;
font-weight: 500;
letter-spacing: 0px;
line-height: 86.88px;
color: rgba(255, 255, 255, 1);


}
.name{

font-size: 30px;
font-weight: 400;
letter-spacing: 1.5px;
line-height: 43.44px;
color: rgba(255, 255, 255, 1);
padding-top: 43px;


}
.name1{
font-size: 22px;
font-weight: 500;
letter-spacing: 0px;
line-height: 31.86px;
color: rgba(255, 255, 255, 1);
padding-bottom: 39px;
}
.name1:hover{
  color: rgb(198, 177, 170);
}
.list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 106px;
}
.blue{

width: 916px;
height: 390px;
opacity: 1;
border-radius: 50px 0 0 0; /* 50px 圆角应用于左上角 */
background: rgba(23, 63, 143, 1);
margin-left: 538px;
margin-top: 690px;

}
.bluetext{
  padding: 74px 59px 91px 80px;
}
.a{
font-size: 22px;
font-weight: 500;
letter-spacing: 0.55px;
line-height: 31.86px;
color: rgba(255, 255, 255, 1);


}
.b{
  padding-top: 100px;
}
.pic{
  width: 600px;
  height: 400px;

}


.line2{
width: 60px;
height: 4px;
opacity: 1;


}
  </style>
  