import { render, staticRenderFns } from "./HelloLive.vue?vue&type=template&id=e654b6fc&scoped=true"
import script from "./HelloLive.vue?vue&type=script&lang=js"
export * from "./HelloLive.vue?vue&type=script&lang=js"
import style0 from "./HelloLive.vue?vue&type=style&index=0&id=e654b6fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e654b6fc",
  null
  
)

export default component.exports